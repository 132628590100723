.contact-container {
  min-height: 500px;
  margin: 0 auto;
  width: 60%;
  padding: 150px 0px 200px;
}

.contact-page-form .MuiTextField-root {
  width: 100%;
}

.contact-submit-btn {
  justify-content: center;
  display: grid;
}

.contact-submit-btn > .MuiButton-root {
  min-width: 130px;
  height: 55px;
  color: #fff;
  padding: 15px 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  border-radius: 5px;
  border: none;
  background: #171717;
  font-size: 20px;
  text-transform: uppercase;
}

.contact-submit-btn .MuiButton-root:hover {
  background: #323232;
}

@media screen and (max-width: 1024px) {
  .contact-container {
    width: 95%;
    padding: 50px 0px 200px;
  }
  .contact-container .MuiTextField-root {
    width: 100%;
  }
  .contact-grid > .MuiGrid2-root {
    width: 100%;
  }
  .location-map {
    padding-top: 50px;
  }
}

@media screen and (max-width: 768px) {
  .contact-container {
    width: 93% !important;
    padding: 50px 0px 200px;
  }
}