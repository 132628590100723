.MuiAppBar-root {
  padding: 10px 100px !important;
}

.navbar-logo {
  width: 160px;
}

.menuStack {
  align-items: center;
}

.menuStack > .MuiTypography-root {
  font: 500 20px 'Libre Franklin', sans-serif;
  margin-left: 30px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  transition: 0.3s;
}

.menuItem:hover {
  color: #e4e8f5 !important;
  cursor: pointer;
}

.menuItem > .MuiStack-root>:not(style)~:not(style) {
  margin-left: 20px !important;
}

.icon .MuiIconButton-root {
  border-radius: 5px;
}

.icon .MuiIconButton-root:hover {
  border-radius: 5px;
}

.navbar-icon-btn > .MuiButtonBase-root {
  z-index: 2;
}

.navbar-icon > .MuiSvgIcon-root {
  fill: #FFFFFF;
  font-size: 2.5rem;
}

.language-button > .MuiSvgIcon-root {
  color: #e4e8f5;
}

.language-button > .MuiOutlinedInput-notchedOutline {
  border: none;
}

.language-button > .MuiButtonBase-root:hover {
  background-color: transparent;
  color: #e4e8f5 !important;
}

.language-option {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  min-width: 130px;
  align-items: center;
}

.language-name {
  grid-area: 1 / 1 / 2 / 2;
}

.language-flag-container {
  grid-area: 1 / 2 / 2 / 3;
  justify-content: flex-end;
  display: flex;
}

.language-flag-image {
  width: 32px;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@media screen and (max-width: 768px) {
  .MuiAppBar-root {
    padding: 0px !important;
  }

  .icon {
    z-index: 2;
  }
  
  .navbar-logo {
    width: 135px;
  }

}

@media screen and (max-width: 1024px) {
  .icon {
    z-index: 2;
    padding: 5px;
  }
}