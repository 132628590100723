.contact {
  height: auto;
  padding: 0px 15%;
  background-color: black;
  padding: 130px 16% 100px 16%;
}

.contact .MuiTypography-h2 {
  padding-bottom: 10px;
  color: #ffffff;
}

.contact-info {
  color: #ffffff;
}

.contact-logo {
  width: 270px;
  height: auto;
  padding-bottom: 70px;
}

.contact-form .MuiTextField-root	 {
  color: #ffffff !important;
}

.submit-btn > .MuiButton-root {
  min-width: 130px;
  height: 55px;
  color: #fff;
  padding: 15px 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  border-radius: 5px;
  border: 2px solid #171717;
  background: #171717;
  font-size: 20px;
  text-transform: uppercase;
}

.submit-btn .MuiButton-root:hover {
  background: #99A7CA;
  color: #212529
}

.footer-location {
  display: flex;
  column-gap: 10px;
  width: fit-content;
}

@media screen and (max-width: 1024px) {
  .contact {
    padding: 130px 2% 100px 2%;
  }
  .contact-logo {
    width: 200px;
    padding-bottom: 30px;
  }
  .contact-grid > .MuiGrid-item {
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {
  .MuiTypography-h2 {
    font-size: 45px !important;
  }
  .MuiTypography-body1 {
    font-size: 17px !important;
    padding-right: 0px !important;
  }
  .MuiFormLabel-root {
    font-size: 17px !important;
  }
  .MuiInputBase-root {
    font-size: 17px !important;
  }
  .contact {
    padding: 70px 5% 100px 6%;
  }
  .contact-form .MuiTextField-root {
    width: 100%;
  }
  .contact-grid > .MuiGrid-item {
    padding: 0px !important;
  }
  .contact > .MuiGrid-root {
    width: 100%;
  }
  .contact-info {
    padding-top: 50px;
    text-align: center;
  }
  .contact-logo {
    width: 170px;
  }
  .submit-btn > .MuiButton-root {
    min-width: none;
    padding: 5px 9px;
    font-size: 17px;
  }
  .footer-left {
    text-align: center;
  }
  .footer-location {
    text-align: left;
  }
}