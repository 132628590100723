.MuiTypography-h2 {
  font-size: 60px
}

.MuiTypography-h3 {
  font-family: 'Abhaya Libre', serif !important;
}

.banner {
  margin-top: -110px;
  background-repeat: no-repeat;
  background-attachment: static;
  background-size: cover;
  background-position: 40% 60%;
  height: 50rem;
  width: 100%;
}

.welcome {
  position: relative;
  top: 35%;
  text-align: center;
  padding: 0% 20%;
}

.banner-btn > .MuiButton-root {
  min-width: 130px;
  height: 55px;
  color: #fff;
  padding: 15px 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  border-radius: 5px;
  border: none;
  background: #171717;
  font-size: 20px;
  text-transform: uppercase;
}

.welcome > .MuiTypography-root {
  font-size: 60px;
  padding-bottom: 30px;
}

.banner-btn .MuiButton-root:hover {
  color: #ffffff;
  background: #323232;
}

.about-us {
  text-align: center;
  padding: 70px 50px;
  height: 450px;
}

.about-us .MuiTypography-h2 {
  padding-bottom: 10px;
}

.about-us .MuiTypography-body1 {
  padding: 30px 23%;
}

.services {
  height: auto;
}

.divider {
  display: block;
  width: 45px;
  height: 2px;
  background: #1f2d4f;
  margin: 0 auto;
  margin-bottom: 15px;
}

.service-background {
  min-height: 400px;
  padding: 55px 15%;
  background-repeat: no-repeat;
  background-attachment: static;
  background-size: cover;
  background-position: 40% 60%;
  color: white;
}

.service-content {
  max-width: 1210px;
}

.location {
  min-height: 750px;
  padding: 55px 10%;
  text-align: center;
}

.location-content {
  padding: 50px 0px;
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 30px;
}

.location-text {
  text-align: start;
}

@media screen and (max-width: 1024px) {
  .welcome {
    top: 25%;
    padding: 0% 15%;
  }
  .welcome > .MuiTypography-h1 {
    font-size: 55px;
  }
  .banner-btn {
    padding-top: 5%;
  }
  .about-us {
    padding: 70px 5% 40px;
    height: auto;
  }
  .about-us .MuiTypography-body1 {
    padding: 30px 15%;
  }
  .service-background {
    min-height: 450px;
    padding: 0px 12%;
  }
  .service-background > p.MuiTypography-root {
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .contact {
    padding: 130px 2% 100px 2%;
  }
  .contact-logo {
    width: 200px;
    padding-bottom: 30px;
  }
  .contact-grid > .MuiGrid-item {
    padding: 0px;
  }
  .location {
    padding: 55px 5%;
  }
  .location-content {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .banner {
    margin-top: -110px;
    height: 40rem;
  }
  .welcome {
    padding: 0% 10%;
    top: 29%;
  }
  .welcome > .MuiTypography-root {
    padding-bottom: 0px;
  }
  .welcome > .MuiTypography-h1 {
    color: #ffffff;
    font-size: 35px;
  }
  .banner-btn {
    padding: 30px 0px 0px 0px;
  }
  .banner-btn > .MuiButton-root {
    color: #e8e8e8;
    background-color: #171717;
    padding: 5px 9px;
    font-size: 17px;
    margin-top: 28px;
  }
  .MuiTypography-h2 {
    font-size: 45px !important;
  }
  .MuiTypography-body1 {
    font-size: 17px !important;
    padding-right: 0px !important;
  }
  .about-us .MuiTypography-body1 {
    padding: 30px 0px;
  }
  .service-background {
    min-height: 400px;
    padding: 20px 5%
  }
  .contact {
    padding: 70px 5% 100px 6%;
  }
  .contact-form .MuiTextField-root {
    width: 100%;
  }
  .contact-grid > .MuiGrid-item {
    padding: 0px !important;
  }
  .contact > .MuiGrid-root {
    width: 100%;
  }
  .contact-info {
    padding-top: 50px;
    text-align: center;
  }
  .contact-logo {
    width: 170px;
  }
  .location {
    padding: 35px 5% !important;
  }
  .location-content {
    display: block;
    margin: 0;
    padding-top: 20px;
  }
  .location-text {
    text-align: center;
    padding-bottom: 30px;
  }
  .location-text > .MuiTypography-h3 {
    font-size: 40px;
  }
  .location-map > iframe {
    width: -webkit-fill-available;
  }
}